import React, { useState } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layouts/courseLandingLayout"
import styled from "styled-components"
import PSModal from "../../components/modal"
import Button from "../../components/buttons/linkButton"
import { Container, Col, Row } from "react-bootstrap"
import Navbar from "../../components/navbars/courseLandingNavbar"
import ReactGA from "react-ga"
import WhiteBoardImg from "../../assets/images/event.png"
import Curriculum from "../../components/prep-curriculum"
import EventForm from "../../components/forms/eventForm"
import courseData from "../../utils/course-data"
import moment from "moment"

const CourseContainer = styled.div`
  display: block;
  background: none;
  background-image: url(${WhiteBoardImg});
  background-size: cover;
  background-position: center center;

  display: block;
  height: 475px;
  background-color: var(--white);
`

const HeroContainer = styled(Container)`
  padding-top: 225px;
  max-width: 1000px;
  font-family: sans-serif;

  .col-hero-text {
    padding-left: 40px;
    h1 {
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--white);
    }
    .subtext {
      color: var(--white);
    }
  }

  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    img {
      max-width: 400px;
      display: block;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
`

const CurriculumSection = styled.section`
  padding-bottom: 80px;
  background-color: var(--blue);
  p {
    color: #fff;
    padding-top: 20px;
    text-align: center;
  }
  h2 {
    margin-top: 40px;
    text-align: center;
    color: var(--white);
  }
  .btn-apply-curriculum {
    margin-top: 40px;
  }
`

const FeaturesSection = styled.section`
  padding-bottom: 80px;
  .support-row {
    margin-top: 80px;
    p {
      text-align: left;
    }
    h2 {
      text-align: left;
    }
    .support-text {
      font-size: 16px;
    }
  }
  p {
    padding-top: 20px;
    text-align: center;
  }
  .features-title {
    padding-top: 40px;
    text-align: center;
  }
  .btn-apply-curriculum {
    margin-bottom: 40px;
    margin-top: 40px;
  }
`

const VideoRow = styled(Row)`
  margin: 0 auto;
  margin-top: 40px;
  max-width: 800px;
`
const DatesRow = styled(Row)`
  margin-top: 40px;
  .dates-container {
    h2 {
      text-align: center;
      padding-top: 20px;
    }
  }
  .dates-table-container {
    padding: 20px 50px 0px 50px;

    h3 {
      padding-left: 15px;
    }
  }
  .dates-table-row {
    margin-bottom: 45px;
    margin-top: 25px;

    h3 {
      padding-left: 15px;
      padding-bottom: 15px;
    }
  }
  .img-box {
    img {
      margin-bottom: 0;
    }
    p {
      text-align: left;
      margin: 0;
      font-size: 18px;
      color: var(--black);
    }
  }
  .dates-price {
    p {
      text-align: center;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 16px;
      color: var(--black);

      a {
        color: var(--blue);
      }

      @media screen and (max-width: 767px) {
        text-align: left;
      }
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-pace {
    p {
      text-align: left;
      padding: 5px 0 5px 0;
      margin: 0;
      font-size: 14px;
      color: var(--black);
    }
    @media screen and (max-width: 767px) {
      padding-top: 20px;
    }
  }
  .dates-cta-container {
    padding-top: 20px;
    text-align: right;
    @media screen and (max-width: 767px) {
      text-align: center;
      padding-top: 25px;
    }
  }
  .dates-cta {
    background: #fff;
    border: 2px solid #000;
    padding: 10px 10px;
    display: inline-block;
    text-align: center;
    color: #000;
    -webkit-text-decoration: none;
    text-decoration: none;
    box-shadow: #000 -10px 8px 0px;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    font-size: 14px;
    min-width: 150px;
    text-align: center;
    font-family: "roboto-mono";
    margin-top: -10px;
    margin-bottom: 20px;
    &:hover {
      background-color: var(--blue);
      color: var(--white);
    }
  }
`

const CurriculumSchedule = styled.div`
  margin-top: 20px;
`

const ModalContainer = styled.div`
  h2 {
    text-align: center;
    padding-bottom: 20px;
  }
  p {
    font-size: 16px;
  }
  .cta-container a {
    width: 100%;
    display: block;

    &:hover {
      background-color: var(--yellow);
    }
  }
`

const Index = ({ location }) => {
  const [show, setShow] = useState(false)
  const [eventToShowInForm, setEventToShowInForm] = useState({
    title: "",
    startDate: "",
  })

  const handleClose = () => setShow(false)

  const handleShow = event => {
    setEventToShowInForm(event)

    setShow(true)
  }

  const fireGTMEvent = () => {
    ReactGA.event({
      category: "CTA - Button",
      action: "Click",
      label: "waitlist",
    })
  }

  return (
    <Layout>
      <CourseContainer>
        <Navbar location={location} />
        <PSModal handleClose={handleClose} handleShow={handleShow} show={show}>
          <EventForm
            title={eventToShowInForm.title}
            date={eventToShowInForm.startDate}
            event_url={eventToShowInForm.event_url}
            close={handleClose}
            align="center"
            price={`${process.env.GATSBY_PREP_PRICE}`}
            location={location}
          />
        </PSModal>
        <HeroContainer>
          <Row>
            <Col className="col-hero-text" md={12}>
              <h1>Upcoming Events_</h1>
              <p className="subtext">
                Attend an upcoming event to get started with coding or learn
                more about Parsity.
              </p>
            </Col>
          </Row>
        </HeroContainer>
      </CourseContainer>
      <FeaturesSection>
        <Container>
          <DatesRow>
            <Col md={12}>
              <div className="dates-container">
                <div className="dates-table-container">
                  {courseData.upcomingEvents.map(function (event, i) {
                    return (
                      <>
                        <Row>
                          <h3>{event.title}</h3>
                        </Row>
                        <Row className="dates-table-row">
                          <Col md={4}>
                            <div className="img-box">
                              <img src={event.img}></img>
                              <p>
                                {moment(event.startDate).format(
                                  "MMMM D, yyyy @ h:mm A"
                                )}
                              </p>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="dates-pace">
                              <p>{event.description}</p>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="dates-cta-container">
                              <div>
                                <Button
                                  classToAdd="dates-cta"
                                  fireGTMEvent={fireGTMEvent}
                                  handleCTAClick={e =>
                                    window.open(event.event_url, "_blank")
                                  }
                                  text={"Register"}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                        {i === courseData.upcomingEvents.length - 1 ? null : (
                          <hr></hr>
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            </Col>
          </DatesRow>
        </Container>
      </FeaturesSection>
    </Layout>
  )
}

export default Index
