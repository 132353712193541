import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form as FormikForm, Field } from 'formik';
import Button from '../buttons/button';
import fetch from 'isomorphic-unfetch';
import * as Yup from 'yup';
import ReactGA from 'react-ga'
import { loadStripe } from "@stripe/stripe-js"
import Cookies from 'js-cookie';
import courseData from '../../utils/course-data';
import moment from 'moment';

const FormContainer = styled.div`
  h1 {
    text-align: left;
    padding: 0 0 10px 0;
  }
  p {
    font-size: 14px;
    color: var(--black);
    text-align: left;
  }
  .radio-input {
    width: 20px;
    margin-right: 5px;
  }
  .radio-label {
    font-weight: bold;
  }
  .date-text {
    text-align: center;
    font-size: 20px;
  }

  &.center {
    h1 {
      text-align: center;
    }
  }
`

const SuccessHeader = styled.h2`
  text-align: center;
`

const ErrorMsg = styled.div`
  color: red;
`

const FieldContainer = styled.div`
  width: 100%;
  font-family: 'roboto-mono';
  margin: 15px 0;
  input {
    width: 100%;
  }
`

const fireGTMEvent = () => {
  ReactGA.event({
    category: 'CTA - Button',
    action: 'Click',
    label: 'waitlist-submit',
  })
};

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required')
});
 
export default function EventForm ({ close, align, location, date, title, event_url }) {
  const [success, setSuccess] = useState(false);

  const submitForm = ({email, firstName, lastName}, ipAddress) => {
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? Cookies.get('hubspotutk') : null;
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const formID = '6258057f-e2cd-4501-97b9-f507590daf89'
    const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/8389530/${formID}`;

    fireGTMEvent();
		
    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'email',
          value: email,
        },
        {
          name: 'firstname',
          value: firstName
        },
        {
          name: 'lastname',
          value: lastName
        },
        {
          name: 'event_title',
          value: title
        },
        {
          name: 'event_url',
          value: event_url
        },
        {
          name: 'event_date',
          value: moment(date).format('MMMM D, YYYY @ h:mm A')
        }
      ],
      context: {
        hutk,
        pageUri,
        pageName,
        ipAddress
      },
    };

    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => res.json())
      .then(() => {
        setSuccess(true);

        setTimeout(function () {
          close();
          setSuccess(false)
        }, 2000);
      })
      .catch(err => {
        console.log(err);
        setSuccess(false);
      });
  };

  const showSuccess = () => { 
    if (success) {
      return (
        <>
          <SuccessHeader>Thanks!</SuccessHeader>
          <br></br>
          <p>Look out for an email from us with the event link! Be sure to check your SPAM!</p>
        </>
      )
    } else {
      return (
        <FormContainer className={(align ? align : null)}>
          <h1>{title} Register</h1>
          <p className='date-text'>{moment(date).format('MMMM D, YYYY @ h:mm A')}</p>
          <p>After filling out the form, you'll get an email with a link to the event.</p>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: ''
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              console.log(values)

              fetch('https://api.ipify.org/?format=json')
                .then(res => res.json())
                .then((data) => {
                  console.log(data);
                  submitForm(values, data.ip)
                })
                .catch(err => {
                  console.log(err);
                })
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <FieldContainer>
                  <Field
                    placeholder='Email Address'
                    name="email" type="email"
                  />
                  <ErrorMsg>
                    {errors.email && touched.email ? <div>{errors.email}</div> : null}
                  </ErrorMsg>
                </FieldContainer>
              
                <FieldContainer>
                  <Field
                    placeholder='First Name'
                    name='firstName'
                  />
                  <ErrorMsg>
                  {errors.firstName && touched.firstName ? (
                    <div>{errors.firstName}</div>
                  ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <FieldContainer>
                  <Field
                    placeholder='Last Name'
                    name="lastName"
                  />
                  <ErrorMsg>
                    {errors.lastName && touched.lastName ? (
                      <div>{errors.lastName}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>
                
                <div className={(align ? `text-${align}` : 'text-left')}>
                  <Button text={'Register'} type='submit' />
                </div>   
              </FormikForm>
            )}
          </Formik>
        </FormContainer>
      )
    }
  };

  return showSuccess();
}