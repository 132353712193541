import styled from 'styled-components';
import React from 'react';

const ComparisonTable = styled.div`
  overflow: hidden;
  width: 90%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid var(--black);
  box-shadow: -15px 15px var(--black);

  .table-top {
    display: flex;
    width: 100%;
    height: 45px;
    font-family: 'roboto-mono';
    border-bottom: 2px solid rgba(0,0,0,0.15);
    background-color: var(--white);

    @media screen and (max-width: 479px) {
      height: 60px;
      padding-right: 5px;
    }
  }

  .table-top-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'roboto-mono';
    
    &.little {
      width: 25%;
    }

    &.big {
      width: 75%;
    }

    .column-title {
      color: var(--black);
      font-size: 20px;

      @media screen and (max-width: 767px) {
        font-size: 22px;
      }

      @media screen and (max-width: 479px) {
        font-size: 16px;
      }
    }
  }

  .table-line {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 45px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: var(--white);
    border-bottom: 1px solid rgba(0,0,0,0.15);

    @media screen and (max-width: 767px) {
      height: 55px;
      padding-left: 5px;
    }
  } 

  .table-column {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'roboto-mono';

    &.little {
      width: 25%;
    }

    &.big {
      width: 75%;
    }

    div {
      @media screen and (max-width: 479px) {
        text-align: left;
        font-size: 11px;
      }
    }
  }

  .right {
    justify-content: flex-end;

    @media screen and (max-width: 479px) {
      text-align: left;
    }
  }

  .check {
    width: 30px;
    margin-bottom: 0;
  }

  .category {
    justify-content: flex-end;
    text-align: right;
    padding-left: 0;
    margin-left: 0;

    @media screen and (max-width: 479px) {
      text-align: left;
      font-size: 11px;
    }
  }

`


const Chart = () => {
  return (
    <ComparisonTable>
      <div className='table-top'>
        <div className='table-top-column little'>
          <div className='column-title'>Week</div>
        </div>
        <div className='table-top-column big'>
          <div className='column-title'>Topic</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Onboarding</div>
        </div>
        <div className='table-column big'>
          <div>Habits, Schedule and LinkedIn Setup</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Week 1</div>
        </div>
        <div className='table-column big'>
          <div>Declaring Variables, Math and Other Basics</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Week 2</div>
        </div>
        <div className='table-column big'>
          <div>Conditionals and Functions</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Week 3</div>
        </div>
        <div className='table-column big'>
          <div>Scope and Arrays</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Week 4</div>
        </div>
        <div className='table-column big'>
          <div>Loops and Array Helper Methods</div>
        </div>
      </div>
      <div className='table-line'>
        <div className='table-column little'>
          <div>Week 5</div>
        </div>
        <div className='table-column big'>
          <div>Objects and Problem Solving</div>
        </div>
      </div>
      
    </ComparisonTable>
  )
}

export default Chart;

